/* eslint-disable  */
import { get } from 'lodash'
import { Analytics } from './index'
import { isSubscriptionJourney } from '../../helpers'

const mapBrand = brand => {
  switch (`${brand}`.toLowerCase()) {
    case 'seat':
      return 'seat'
    case 'Audi':
      return 'audi'
    case 'SEAT':
      return 'seat'
    case 'Volkswagen':
      return 'vw'
    case 'Skoda':
      return 'skoda'
    case 'CUPRA':
      return 'cupra'
    default:
      return `${brand}`.toLowerCase()
  }
}

export function pushStorefrontDataToDatalayer(storefrontData) {
  const subscriptionJourney = isSubscriptionJourney()
  const checkString = str => {
    try {
      return str.trim().split(' ')[0]
    } catch (error) {
      return ''
    }
  }
  const vehicleModelName = checkString(
    get(storefrontData, 'vehicleData.model.name')
  )
  let yearlyMileage = 0
  yearlyMileage = get(
    storefrontData,
    'financialProduct.calculation.yearlyMileage'
  )
  if (!yearlyMileage) {
    const monthlyMileage = get(
      storefrontData,
      'financialProduct.calculation.monthlyMileage'
    )
    yearlyMileage = monthlyMileage * 12
  }
  const contractAmount =
    get(storefrontData, 'financialProduct.calculation.duration') *
    get(
      storefrontData,
      'financialProduct.calculation.subsequentInstallment.amount'
    )
  if (subscriptionJourney) {
    let additionalProducts = ''
    try {
      additionalProducts = get(
        storefrontData,
        'financialProduct.additionalProducts'
      )
      if (additionalProduct && Array.isArray(additionalProduct)) {
        additionalProducts =
          additionalProducts.length &&
          additionalProducts[0] &&
          additionalProducts[0].qualifiers &&
          additionalProducts[0].qualifiers.lenght &&
          additionalProducts[0].qualifiers[0] &&
          additionalProducts[0].qualifiers[0].stringValue
      } else {
        additionalProducts?.qualifiers?.stringValue
      }
    } catch (error) {
      // console.error(error)
    }
    Analytics.addToDatalayer({
      'product[0].attributes.recurringPayment': get(
        storefrontData,
        'financialProduct.calculation.subsequentInstallment.amount'
      ),
      'product[0].attributes.duration': get(
        storefrontData,
        'financialProduct.calculation.duration'
      ),
      'product[0].attributes.durationUnit': get(
        storefrontData,
        'financialProduct.calculation.durationUnit'
      ),
      'product[0].attributes.yearlyMileage': yearlyMileage,
      'product[0].attributes.mileageUnit': get(
        storefrontData,
        'financialProduct.calculation.mileageUnit'
      ),
      'product[0].attributes.downPaymentAmount': get(
        storefrontData,
        'financialProduct.calculation.downPaymentAmount'
      ),
      'product[0].attributes.contractAmount': contractAmount,
      'product[0].vehicleModel[0].manufacturer': get(
        storefrontData,
        'vehicleData.model.manufacturer'
      ),
      'product[0].vehicleModel[0].descriptionLong': get(
        storefrontData,
        'vehicleData.model.description'
      ),
      'product[0].vehicleModel[0].name': get(
        storefrontData,
        'vehicleData.model.name'
      ),
      'product[0].attributes.addOns': additionalProducts,
      'dealerData.companyId': get(storefrontData, 'dealerData.companyId'),
      'dealerData.companyName': get(storefrontData, 'dealerData.companyName'),
      'dealerData.regionId': null,
      'dealerData.KVPS': get(storefrontData, 'dealerData.companyId'),
      'dealerData.address.street': get(
        storefrontData,
        'dealerData.address.street'
      ),
      'dealerData.address.zipCode': get(
        storefrontData,
        'dealerData.address.zipCode'
      ),
      'dealerData.address.city': get(storefrontData, 'dealerData.address.city'),
      'dealerData.address.state': null,
      'core.attributes.brand': mapBrand(get(storefrontData, 'brand')),
    })
  } else {
    let additionalProducts = ''
    try {
      additionalProducts = get(
        storefrontData,
        'financialProduct.additionalProducts'
      )
      if (Array.isArray(additionalProduct)) {
        additionalProducts =
          additionalProducts.length &&
          additionalProducts[0] &&
          additionalProducts[0].qualifiers &&
          additionalProducts[0].qualifiers.lenght &&
          additionalProducts[0].qualifiers[0] &&
          additionalProducts[0].qualifiers[0].stringValue
      } else {
        additionalProducts?.qualifiers?.stringValue
      }
    } catch (error) {
      // console.error(error)
    }
    Analytics.addToDatalayer({
      'product[0].attributes.recurringPayment': get(
        storefrontData,
        'financialProduct.calculation.subsequentInstallment.amount'
      ),
      'product[0].attributes.duration': get(
        storefrontData,
        'financialProduct.calculation.duration'
      ),
      'product[0].attributes.durationUnit': get(
        storefrontData,
        'financialProduct.calculation.durationUnit'
      ),
      'product[0].attributes.yearlyMileage': yearlyMileage,
      'product[0].attributes.mileageUnit': get(
        storefrontData,
        'financialProduct.calculation.mileageUnit'
      ),
      'product[0].attributes.downPaymentAmount': get(
        storefrontData,
        'financialProduct.calculation.downPaymentAmount'
      ),
      'product[0].attributes.contractAmount': contractAmount,
      'product[0].vehicleModel[0].manufacturer': get(
        storefrontData,
        'vehicleData.model.manufacturer'
      ),
      'product[0].vehicleModel[0].descriptionLong': get(
        storefrontData,
        'vehicleData.model.name'
      ),
      'product[0].vehicleModel[0].name': vehicleModelName,
      'product[0].attributes.addOns': additionalProducts,
      'dealerData.companyId': get(storefrontData, 'dealerData.companyId'),
      'dealerData.companyName': get(storefrontData, 'dealerData.companyName'),
      'dealerData.regionId': null,
      'dealerData.KVPS': get(storefrontData, 'dealerData.companyId'),
      'dealerData.address.street': get(
        storefrontData,
        'dealerData.address.street'
      ),
      'dealerData.address.zipCode': get(
        storefrontData,
        'dealerData.address.zipCode'
      ),
      'dealerData.address.city': get(storefrontData, 'dealerData.address.city'),
      'dealerData.address.state': null,
      'core.attributes.brand': mapBrand(get(storefrontData, 'brand')),
    })
  }
}
