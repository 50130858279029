export const BRAND_NAME = process.env.REACT_APP_WEBSITE_BRAND

export const BASE_BRAND_NAME = process.env.REACT_APP_WEBSITE_BASE_BRAND

export const PRODUCT = process.env.REACT_APP_PRODUCT

export const ENTRYPOINT = process.env.REACT_APP_ENTRYPOINT

export const CONNECTION_TIMEOUT = 30000

export const POLLING_INTERVAL = 2000

export const MAX_POLLING_CYCLES = 60

export const MEDIA_STORAGE_EXPIRATION_DAYS = 30

export const DATE_FORMAT = 'DD/MM/YYYY'

export const DATE_SEPARATOR = DATE_FORMAT.split('').find(item => {
  return !item.match(/^[a-zA-Z]*$/)
})

export const NUMBER_FORMAT = 'es'

export const CURRENCY = 'EUR'

export const CURRENCY_SYMBOL = '€'

export const ANALYTICS_DEFAULTS = {
  viewChange: '',
  pagename: 'Application Form',
  platform: 'operatelease',
  country: 'ES',
  language: 'es',
  brand: BRAND_NAME,
}

export const FILE_UPLOAD_STATUS = {
  SUCCESS: 'SUCCESS',
  LOADING: 'LOADING',
  UNSUPPORTED: 'UNSUPPORTED',
  TOO_BIG: 'TOO_BIG',
  ERROR: 'ERROR',
}

export const ERRORS = {
  TIMEOUT: 'timeout',
  POLLING_ERROR: 'polling error',
  GENERAL: 'general error',
}

export const MAX_FILESIZE = 1000 * 1000 * 4

export const getAnalyticsBrand = brand => {
  if (!brand || !brand.length) {
    return ANALYTICS_DEFAULTS.brand
  }
  return brand.toLowerCase() === 'volkswagen' ? 'vw' : brand.toLowerCase()
}

export const ADOBE_API_KEY = process.env.REACT_APP_ADOBE_API_KEY

const CSBR_BRANDS = ['vwfs']

// some aspects of the visual presentation are different for CSbR
export const CSBR_MODE_ENABLED =
  CSBR_BRANDS.includes(BASE_BRAND_NAME) && BRAND_NAME === 'vwfscsbr'

export const isACS = entrypoint => entrypoint === 'acs'

export const isVTP = entrypoint => entrypoint === 'vtp'

export const isCSBR = entrypoint => {
  return (
    [BASE_BRAND_NAME, BRAND_NAME].includes('vwfscsbr') || isACS(entrypoint) || isVTP(entrypoint)
  )
}

export const STEP = {
  PERSONAL: 'personalDetails',
  CONTACT: 'contactDetails',
  EMPLOYMENT: 'occupationalDetails',
  FINANCIAL: 'financialDetails',
  DATA: 'dataProtectionDetails',
  SUMMARY: 'summary',
}

export const getAnalyticsContext = () => {
  switch (PRODUCT) {
    case 'privatelease':
      switch (ENTRYPOINT) {
        case 'acs':
        case 'vtp':
        case 'd2o':
          return 'TO BE CLARIFIED'
        case 'oneshop':
          switch (BRAND_NAME) {
            case 'cupra':
              return 'ONE.Shop Cupra'
            case 'vwfs':
            case 'vwn':
            case 'vw':
              return 'ONE.Shop VW'
            case 'audi':
              return 'ONE.Shop Audi'
            case 'seat':
              return 'ONE.Shop Seat'
            case 'skoda':
              return 'ONE.Shop Skoda'
            default:
              return null
          }
        default:
          return null
      }
    default:
      return null
  }
}
